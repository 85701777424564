



















































































import { scrollLock } from '@/composition/scrollLock'
import { useWindowKeyboardListener } from '@/composition/windowKeyboardListener'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'BaseModal',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    ariaLabel: {
      type: String,
      required: true,
    },

    to: {
      type: String,
      default: 'modal-container',
    },

    scrollLock: {
      type: Boolean,
      default: true,
    },

    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },

    closeWithEscapeKey: {
      type: Boolean,
      default: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const hasFooter = computed(() => Boolean(context.slots.footer))

    onMounted(() => {
      if (props.scrollLock) {
        watch(
          () => props.modelValue,
          (newValue) => {
            if (newValue) {
              scrollLock.enable()
            } else {
              scrollLock.disable()
            }
          },
          {
            immediate: true,
          }
        )
      }
    })

    onUnmounted(() => {
      if (props.scrollLock && scrollLock.isLocked.value) {
        scrollLock.disable()
      }
    })

    if (props.closeWithEscapeKey) {
      useWindowKeyboardListener({
        key: 'Escape',
        handler: (evt) => {
          if (
            evt &&
            evt.target &&
            ['INPUT', 'TEXTAREA'].includes((evt.target as HTMLElement).nodeName)
          )
            return

          close()
        },
      })
    }

    function onBackdropClick() {
      if (!props.closeOnBackdropClick) return

      close()
    }

    function close() {
      context.emit('update', false)
    }

    return {
      hasFooter,
      onBackdropClick,
      close,
    }
  },
})
