





















































import { Color, colors } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'

type ButtonType = 'button' | 'reset' | 'submit'
export type ButtonVariant = 'solid' | 'ghost' | 'soft' | 'transparent'

export const buttonToneConfig: Record<
  Color,
  Record<ButtonVariant, (string | Record<string, boolean>)[]>
> = {
  primary: {
    solid: ['text-primary-inverse', 'bg-primary', 'hover:text-primary-400'],
    ghost: [
      'text-primary-400',
      'hover:text-primary-inverse',
      'hover:bg-primary',
      'hover:border-primary',
    ],
    soft: [
      'text-primary-400',
      'bg-primary-50',
      'hover:bg-primary-100',
      'dark:hover:border-primary-700',
      'dark:hover:bg-primary-700',
      'dark:text-primary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-primary-400', 'hover:bg-primary-50'],
  },
  secondary: {
    solid: ['text-secondary-inverse', 'bg-secondary', 'hover:text-secondary'],
    ghost: [
      'text-secondary',
      'hover:text-secondary-inverse',
      'hover:bg-secondary',
      'hover:border-secondary',
    ],
    soft: [
      'text-secondary',
      'bg-secondary-50',
      'hover:bg-secondary-100',
      'dark:hover:bg-secondary-700',
      'dark:text-secondary-100',
      'dark:hover:bg-secondary-40',
    ],
    transparent: ['text-secondary', 'hover:bg-secondary-100'],
  },
  tertiary: {
    solid: ['text-tertiary-inverse', 'bg-tertiary', 'hover:text-tertiary'],
    ghost: [
      'text-tertiary',
      'hover:text-tertiary-inverse',
      'hover:bg-tertiary',
      'hover:border-tertiary',
    ],
    soft: [
      'text-tertiary',
      'bg-tertiary-50',
      'hover:bg-tertiary-100',
      'dark:hover:bg-tertiary-700',
      'dark:text-tertiary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-tertiary', 'hover:bg-tertiary-100'],
  },
  quaternary: {
    solid: [
      'text-quaternary-inverse',
      'bg-quaternary',
      'hover:text-quaternary',
    ],
    ghost: [
      'text-quaternary',
      'hover:text-quaternary-inverse',
      'hover:bg-quaternary',
      'hover:border-quaternary',
    ],
    soft: [
      'text-quaternary',
      'bg-quaternary-50',
      'hover:bg-quaternary-100',
      'dark:hover:bg-quaternary-700',
      'dark:text-quaternary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-quaternary', 'hover:bg-quaternary-100'],
  },
  quinary: {
    solid: ['text-quinary-inverse', 'bg-quinary', 'hover:text-quinary'],
    ghost: [
      'text-quinary',
      'hover:text-quinary-inverse',
      'hover:bg-quinary',
      'hover:border-quinary',
    ],
    soft: [
      'text-quinary',
      'bg-quinary-50',
      'hover:bg-quinary-100',
      'dark:hover:bg-quinary-700',
      'dark:text-quinary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-quinary', 'hover:bg-quinary-100'],
  },
  critical: {
    solid: ['text-critical-inverse', 'bg-critical', 'hover:text-critical'],
    ghost: [
      'text-critical',
      'hover:text-critical-inverse',
      'hover:bg-critical',
      'hover:border-critical',
    ],
    soft: [
      'text-critical',
      'bg-critical-50',
      'hover:bg-critical-100',
      'dark:hover:bg-critical-700',
      'dark:text-critical-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-critical', 'hover:bg-critical-100'],
  },
  caution: {
    solid: ['text-caution-inverse', 'bg-caution', 'hover:text-caution'],
    ghost: [
      'text-caution',
      'hover:text-caution-inverse',
      'hover:bg-caution',
      'hover:border-caution',
    ],
    soft: [
      'text-caution',
      'bg-caution-50',
      'hover:bg-caution-100',
      'dark:hover:bg-caution-700',
      'dark:text-caution-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-caution', 'hover:bg-caution-100'],
  },
  positive: {
    solid: ['text-positive-inverse', 'bg-positive', 'hover:text-positive'],
    ghost: [
      'text-positive',
      'hover:text-positive-inverse',
      'hover:bg-positive',
      'hover:border-positive',
    ],
    soft: [
      'text-positive',
      'bg-positive-50',
      'hover:bg-positive-100',
      'dark:hover:bg-positive-700',
      'dark:text-positive-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-positive', 'hover:bg-positive-100'],
  },
  neutral: {
    solid: ['text-neutral-inverse', 'bg-neutral', 'hover:text-neutral'],
    ghost: [
      'text-neutral',
      'hover:text-neutral-inverse',
      'hover:bg-neutral',
      'hover:border-neutral',
    ],
    soft: [
      'text-neutral',
      'bg-neutral-50',
      'hover:bg-neutral-100',
      'dark:hover:bg-neutral-700',
      'dark:text-neutral-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-neutral', 'hover:bg-neutral-100'],
  },
  info: {
    solid: ['text-info-inverse', 'bg-info', 'hover:text-info'],
    ghost: [
      'text-info',
      'hover:text-info-inverse',
      'hover:bg-info',
      'hover:border-info',
    ],
    soft: [
      'text-info',
      'bg-info-50',
      'hover:bg-info-100',
      'dark:hover:bg-info-700',
      'dark:text-info-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-info', 'hover:bg-info-100'],
  },
  promote: {
    solid: ['text-promote-inverse', 'bg-promote', 'hover:text-promote'],
    ghost: [
      'text-promote',
      'hover:text-promote-inverse',
      'hover:bg-promote',
      'hover:border-promote',
    ],
    soft: [
      'text-promote',
      'bg-promote-50',
      'hover:bg-promote-100',
      'dark:hover:bg-promote-700',
      'dark:text-promote-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-promote', 'hover:bg-promote-100'],
  },
}

export const buttonBaseClasses = [
  'w-full',
  'sm:w-auto',
  'font-normal',
  'leading-6',
  'text-lg',
  'text-center',
  'p-4',
  'border',
  'rounded-md',
  'transition-colors',
  'duration-200',
  'focus:outline-none',
  'focus-visible:ring-2',
  'dark:bg-opacity-90',
]

export const buttonVariantConfig: Record<ButtonVariant, string[]> = {
  solid: ['border-transparent', 'hover:border-current', 'hover:bg-transparent'],
  ghost: ['border-current'],
  soft: ['border-transparent'],
  transparent: ['border-transparent'],
}

export default defineComponent({
  name: 'BaseButton',

  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'button',
      validator: (value: string) =>
        ['button', 'reset', 'submit'].includes(value),
    },

    tone: {
      type: String as PropType<Color>,
      default: 'neutral' as Color,
      validator: (value: Color) => colors.includes(value),
    },

    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'solid' as ButtonVariant,
      validator: (value: string) =>
        ['solid', 'ghost', 'soft', 'transparent'].includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const computedClass = computed(() => [
      ...buttonBaseClasses,
      ...buttonToneConfig[props.tone][props.variant],
      ...buttonVariantConfig[props.variant],
      ...(props.disabled ? ['opacity-50'] : []),
      ...(props.loading ? ['relative pointer-events-none select-none'] : []),
    ])

    const computedAttributes = computed(() => ({
      disabled: props.disabled || props.loading,
    }))

    return {
      computedClass,
      computedAttributes,
    }
  },
})
