


































































import { useSiteHeader } from '@/composition/siteHeader'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
// import { currentRoute } from '@/router'
import { gsap } from 'gsap'
import WhatsAppModal from '@/components/WhatsAppModal.vue'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    SiteHeader,
    SiteFooter,
    WhatsAppModal,
  },

  setup() {
    const isModalOpen = ref(false)

    const wName = ref('')
    const wEmail = ref('')
    const wPhone = ref('')

    const { items: navItems } = useSiteHeader({
      items: [
        {
          id: 'aboutUs',
          i18nPath: 'routes.aboutUs',
          toRoute: {
            name: 'AboutUs',
          },
        },
        // {
        //   id: 'autodiagnosis',
        //   i18nPath: 'routes.autodiagnosis',
        //   toRoute: {
        //     name: 'Autodiagnosis',
        //   },
        // },
        {
          id: 'gallery',
          i18nPath: 'routes.gallery',
          toRoute: {
            name: 'Gallery',
          },
        },
        {
          id: 'services',
          i18nPath: 'routes.services',
          toRoute: {
            name: 'Services',
          },
        },
        {
          id: 'intervention',
          i18nPath: 'routes.intervention',
          toRoute: {
            name: 'Home',
            hash: '#home-5',
          },
        },
        {
          id: 'pdf',
          i18nPath: 'routes.pdf',
          href: '/pdf/SALUD_MENTAL_y_ADICCIONES_SEPTIEMBRE.pdf',
        },
        {
          id: 'contact',
          i18nPath: 'routes.contact',
          toRoute: {
            name: 'Home',
            hash: '#home-6',
          },
        },
      ],
    })

    function viewBeforeEnter(el: Element) {
      gsap.set(el, {
        opacity: 0,
      })
    }

    function viewEnter(el: Element, done: () => void) {
      gsap.to(el, {
        duration: 0.3,
        opacity: 1,
        ease: 'linear',
        onComplete: done,
      })
    }

    function viewLeave(el: Element, done: () => void) {
      gsap.to(el, {
        duration: 0.3,
        opacity: 0,
        ease: 'linear',
        onComplete: done,
      })
    }

    function getLink(name: string, email: string, phone: string) {
      return `https://api.whatsapp.com/send?phone=521662215555&text=Hola,%20quiero%20que%20me%20proporcionen%20información%20de%20sus%20servicios,%20${name},%20${email},%20${phone}`
    }

    function closeModal(): void {
      if (!isModalOpen.value) return
      isModalOpen.value = false
    }

    function toggleModal(): void {
      isModalOpen.value = !isModalOpen.value
    }

    function clearModal(): void {
      isModalOpen.value = false
    }

    return {
      getLink,
      isModalOpen,
      closeModal,
      toggleModal,
      navItems,
      viewBeforeEnter,
      viewEnter,
      viewLeave,
      wName,
      wPhone,
      wEmail,
      clearModal,
    }
  },
})
