import { SectionModel, sectionService } from '@/services/modules/section'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { useServicePaginated } from './servicePaginated'

export function useSection(model?: ComputedRef<SectionModel> | SectionModel) {
  const request = computed<SectionModel>(() => ({
    languageId: currentLanguage.value.guid,
    pageNumber: 1,
    rowsPerPage: 5,
    ...(isRef(model) ? model.value : model),
  }))
  return useServicePaginated(request, () => sectionService.get(request.value))
}
