






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseBox',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
})
