



















import { defineComponent } from '@vue/composition-api'
import BrandLogo from '@/components/branding/BrandLogo.vue'

export default defineComponent({
  name: 'LoadingScreen',
  components: {
    BrandLogo,
  },
})
