var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"model-value":_vm.modelValue,"aria-label":"whatsapp"},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"header"}})]},proxy:true},{key:"default",fn:function(){return [_c('BaseBox',{staticClass:"my-6"},[_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",on:{"submit":function($event){handleSubmit(_vm.getLink(_vm.wName, _vm.wPhone, _vm.wEmail))},"reset":function($event){$event.preventDefault();reset;}}},[_c('BaseBox',{staticClass:"grid gap-y-8"},[_c('ValidationProvider',{attrs:{"name":_vm.nameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{staticClass:"border",attrs:{"id":"waName","type":"text","placeholder":_vm.namePlaceholder},model:{value:(_vm.wName),callback:function ($$v) {_vm.wName=$$v},expression:"wName"}}),_c('BaseFieldMessage',{attrs:{"id":"first-name-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.emailLabel,"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{staticClass:"border",attrs:{"id":"waEmail","type":"email","placeholder":_vm.emailPlaceholder},model:{value:(_vm.wEmail),callback:function ($$v) {_vm.wEmail=$$v},expression:"wEmail"}}),_c('BaseFieldMessage',{attrs:{"id":"email-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.phoneLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{directives:[{name:"mask",rawName:"v-mask",value:("(###) ###-####"),expression:"`(###) ###-####`"}],staticClass:"border",attrs:{"id":"waPhone","type":"tel","placeholder":_vm.phonePlaceholder},model:{value:(_vm.wPhone),callback:function ($$v) {_vm.wPhone=$$v},expression:"wPhone"}}),_c('BaseFieldMessage',{attrs:{"id":"phone-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)})],1),_c('BaseBox',{staticClass:"p-3 pt-10",attrs:{"tag":"footer"}},[_c('BaseButton',{staticClass:"-m-3 p-3 flex rounded-md",attrs:{"type":"submit","tone":"tertiary"}},[_c('i18n',{attrs:{"tag":false,"path":"button"}})],1)],1)],1)]}}])})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('BrandLogo',{staticClass:"w-auto h-12"})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }