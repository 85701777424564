import { api } from '@/services/client'
import { AxiosInstance } from 'axios'
import { PaginationList, PaginationSelectModel, serviceUrl } from '../utils'

const controller = serviceUrl('section')

function createSectionService(client: AxiosInstance) {
  return {
    get(params?: SectionModel) {
      return client.get<PaginationList<SectionViewModel>>(
        controller.action('get'),
        {
          params,
        }
      )
    },
  }
}

export const sectionService = createSectionService(api.base)

export interface SectionModel extends PaginationSelectModel {
  languageId?: string
}

export interface SectionViewModel {
  sectionId: string
  sectionKey: number
  pageName: string
  name: string
  groupName: string
  title: string
  description: string
  content: string
  rowCount: number
}
