












































































































import i18n from '@/setup/i18n'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BrandLogo from './branding/BrandLogo.vue'

export default defineComponent({
  name: 'WhatsAppModal',
  components: {
    ValidationObserver,
    ValidationProvider,
    BrandLogo,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    const wName = ref('')
    const wEmail = ref('')
    const wPhone = ref('')

    function getLink(name: string, email: string, phone: string) {
      return window.open(
        `https://api.whatsapp.com/send?phone=521662215555&text=Hola,%20quiero%20que%20me%20proporcionen%20información%20de%20sus%20servicios,%20${name},%20${email},%20${phone}`
      )
    }

    const nameLabel = computed(() => i18n.t('contactFields.firstName.label'))
    const namePlaceholder = computed(() =>
      i18n.t('contactFields.firstName.placeholder')
    )

    const emailLabel = computed(() => i18n.t('contactFields.email.label'))
    const emailPlaceholder = computed(() =>
      i18n.t('contactFields.email.placeholder')
    )

    const phoneLabel = computed(() => i18n.t('contactFields.phone.label'))
    const phonePlaceholder = computed(() =>
      i18n.t('contactFields.phone.placeholder')
    )

    return {
      wName,
      wEmail,
      wPhone,
      getLink,
      namePlaceholder,
      emailPlaceholder,
      phonePlaceholder,
      nameLabel,
      emailLabel,
      phoneLabel,
      onUpdate,
    }
  },
})
