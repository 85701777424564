








































































































































































































































import SiteLanguageLinks from '@/components/SiteLanguageLinks.vue'
import { defineComponent, computed, PropType } from '@vue/composition-api'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import {
  address,
  phone,
  phone2,
  arizonaPhone,
  medicalCenterPhone,
  email,
  socials,
} from '@/app.config.json'
import { formatPhoneNumber } from '@/utils/phone'

export default defineComponent({
  name: 'SiteFooter',
  components: { SiteLanguageLinks },

  props: {
    siteMapItems: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup() {
    const currentYear = computed(() => new Date().getUTCFullYear())

    return {
      currentYear,
      address,
      phone,
      phone2,
      arizonaPhone,
      medicalCenterPhone,
      formattedPhone: formatPhoneNumber(phone),
      formattedPhone2: formatPhoneNumber(phone2),
      formattedArizonaPhone: formatPhoneNumber(arizonaPhone),
      formattedMedicalCenterPhone: formatPhoneNumber(medicalCenterPhone),
      email,
      socials,
    }
  },
})
