






































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { useSection } from '@/composition/section'
import router from '@/router'
import { SectionViewModel } from '@/services/modules/section'
import i18n from '@/setup/i18n'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SearchModal',

  components: {
    BrandLogo,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const notFound = ref(false)
    const searchBy = ref('')

    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    const searchLabel = computed(() => i18n.t('searchBy'))

    const { data: sections, isLoading: isLoadingSections } = useSection(
      computed(() => ({
        searchBy: searchBy.value,
      }))
    )

    function onSectionClick(section: SectionViewModel) {
      router.push({ name: section.pageName, hash: `#${section.name}` })
      searchBy.value = ''
      onUpdate(false)
    }

    return {
      notFound,
      searchLabel,
      sections,
      isLoadingSections,
      onUpdate,
      searchBy,
      onSectionClick,
    }
  },
})
