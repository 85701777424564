





























































































































































































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import SiteLanguageLinks from '@/components/SiteLanguageLinks.vue'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import { currentRoute } from '@/router'
import { title, socials, phone } from '@/app.config.json'
import { formatPhoneNumber } from '@/utils/phone'
import SearchModal from '@/components/SearchModal.vue'
import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import BaseButton from './_base/BaseButton.vue'
import { useSection } from '@/composition/section'
// import axios from 'axios'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    SearchModal,
    BrandLogo,
    SiteLanguageLinks,
    BaseButton,
  },

  props: {
    items: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup() {
    const isMobileMenuOpen = ref(false)
    const isSearchModalOpen = ref(false)
    const searchBy = ref('')
    const modal = false

    const { data: sections, isLoading: isLoadingSections } = useSection(
      computed(() => ({
        searchBy: searchBy.value,
      }))
    )

    watch(
      () => currentRoute.value,
      () => {
        isMobileMenuOpen.value = false
      }
    )

    function toggleSearchModal(): void {
      isSearchModalOpen.value = !isSearchModalOpen.value
    }

    function closeMenu(): void {
      if (!isMobileMenuOpen.value) return
      isMobileMenuOpen.value = false
    }

    function toggleMenu(): void {
      isMobileMenuOpen.value = !isMobileMenuOpen.value
    }

    return {
      toggleSearchModal,
      isSearchModalOpen,
      modal,
      searchBy,
      sections,
      isLoadingSections,
      title,
      socials,
      isMobileMenuOpen,
      phone,
      formattedPhone: formatPhoneNumber(phone),
      closeMenu,
      toggleMenu,
    }
  },
})
